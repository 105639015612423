import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise"; // Import Ag-Grid Enterprise features
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-quartz.css";
import "../AgTable.scss";
// import "../AgTheme.css"
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { handleAgCellDataFormat, handleCellRenderer } from "../AgUtility";
import moment from "moment";

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useImperativeHandle
} from "react";
import PDFViewerTool from "../HelperTools/PDFViewerTool";
import PDFViewerToolV2 from "../HelperTools/PDFViewerTool/pdfViewerV2";
import { LicenseManager } from "ag-grid-enterprise";



const detailCellRendererParams = {
  detailGridOptions: {
    columnDefs: [{ field: "price", headerName: "Price" }],
  },
  getDetailRowData: (params) => {
    params.successCallback(params.data.details);
  },
};

const AgTableClient = forwardRef(function (props, ref) {
  // Column definitions
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const gridRef = useRef();
  const processColumnDefs = (columnDefs) => {
    return columnDefs.map((obj) => ({
      ...obj, // Spread the original object
      ...(obj.formatType ? handleCellRenderer() : {}), // Add the new key-value pair
      ...(obj.editRow
        ? {
            editFunction: (params, status, colKey) =>
              handleCellEditing(params, status, colKey),
          }
        : {}),
    }));
  };

  useEffect(() => {
    LicenseManager.setLicenseKey(
      "Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-064524}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Finkraft}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{finkraft}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{finkraft}_need_to_be_licensed___{finkraft}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{5_August_2025}____[v3]_[0102]_MTc1NDM0ODQwMDAwMA==f6fa016c1d5a2cae512ac9e9a68e6239"
    );
  }, []);

  useEffect(() => {
    if (gridApi && gridColumnApi) {
      props.autoResize && gridApi.sizeColumnsToFit();
    }
  }, [props.columnDefs, gridApi, gridColumnApi]);

  const onGridReady = useCallback(async (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    console.log("grid ready",params)

    console.log("Grid Data:", params.api.getDisplayedRowAtIndex(0)?.data);

    params.api.autoSizeAllColumns();
  }, []);


  const onCellValueChanged = useCallback((event) => {}, []);

  const onRowValueChanged = useCallback((event) => {
    props.onRowSaved && props.onRowSaved(event.data);
  }, []);

  const handleCellEditing = (params, editingOn, colKey) => {
    if (!editingOn) {
      onBtStopEditing();
    } else {
      onBtStartEditing(params, colKey);
    }
  };
  const onBtStartEditing = useCallback((params, colKey) => {
    gridRef.current.api.setFocusedCell(params?.rowIndex, colKey);
    gridRef.current.api.startEditingCell({
      rowIndex: params?.rowIndex,
      colKey: colKey,
    });
  }, []);

  const onBtStopEditing = useCallback(() => {
    gridRef.current.api.stopEditing();
  }, []);


  const exportDataToExcel = useCallback(() => {
    if (gridApi && gridColumnApi) {
      const columnState = gridColumnApi.getColumnState();
      const allColumnKeys = columnState.map(state => state.colId);

      gridApi.exportDataAsExcel({
        columnKeys: allColumnKeys,
        processCellCallback: (params) => {
          const colDef = params.column.getColDef();

          if (colDef.exportIgnore) {
            return null;
          }

          if (Array.isArray(params.value)) {
            return params.value.join(", ");
          }

          return params.value ?? ""; 
        },
        processHeaderCallback: (params) => {
          const colDef = params.column.getColDef();
          if (colDef.exportIgnore) {
            return null;
          }
          return colDef.headerName || "";
        },
      });
    } else {
      console.error('API or Column API is not available');
    }
  }, [gridApi, gridColumnApi]);




  const exportDataToCSV = useCallback(() => {
    const params = {
      suppressQuotes: true, // Suppress quotes around cell values
      processHeaderCallback: (params) => {
        // Convert column headers to lowercase
        return params.column.getColDef().headerName.toLowerCase();
      },
      processCellCallback: (params) => {
        // Convert cell values to lowercase if they are strings

        return params.value;
      },
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, [gridRef]);

  function applyFilter(gridApi, columnName, filterValue) {
    // Get the filter instance for the column
    gridApi.getFilterInstance(columnName, (filterInstance) => {
      // Set the filter model
      filterInstance.setModel({
        type: "contains", // or 'equals', 'lessThan', 'greaterThan', etc.
        filter: filterValue,
      });

      // Apply the filter
      gridApi.onFilterChanged();
    });
  }

  const onFilterTextBoxChanged = useCallback((query) => {
    gridRef.current &&
      gridRef.current.api.setGridOption("quickFilterText", query);
  }, []);

  

  React.useImperativeHandle(ref, () => ({
    exportDataToExcel,
    exportDataToCSV,
    exportJSONData,
    getAllColumns,
    onFilterTextBoxChanged,
    onDateFilterChanged,
    onColumnRowGroupChanged,
    setFilterModel: (filterModel) => {
      console.log("filter modal", filterModel);
      gridRef.current.api.setFilterModel(filterModel);
    },
 
    setSortModel: (sortModel) => {
      if (gridRef.current) {
        gridRef.current.api.setSortModel(sortModel);
      }
    },
    applyColumnState: (sortModel) => {
      if (gridRef.current) {
        gridRef.current.api.applyColumnState({
          state: sortModel.map((s) => ({ colId: s.colId, sort: s.sort })),
        });
      }
    },
    setGroupModel: (groupModel) => {
      gridRef.current.columnApi.setRowGroupColumns(
        groupModel.map((group) => group.colId || group.field)
      );
    },
    refreshGrid: () => {
      if (gridRef.current?.api) {
        gridRef.current.api.refreshHeader();
        gridRef.current.api.refreshCells({ force: true });
        gridRef.current.api.refreshView();
      }
    },
  }));

  
  

  const onColumnRowGroupChanged = (params) => {
    const rowGroupColumns = params.api.getRowGroupColumns();
    const isGrouped = rowGroupColumns.length > 0;
    const columnsToUpdate = [];
    params.api.getAllGridColumns().forEach(col => {
      const colDef = col.getColDef();
      if (colDef.hideColumnInGroup) {
        columnsToUpdate.push(col.getColId());
      }
    });
    params.api.setColumnsVisible(columnsToUpdate, !isGrouped);
  };
  
  
  

  const exportJSONData = () => {
    if (gridApi) {
      const rowData = [];
      gridApi.forEachNode((node) => rowData.push(node.data));

      const transformedData = rowData.map((row) => {
        const obj = {};
        props.columnDefs.forEach((column) => {
          obj[column.field] = row[column.field];
        });
        return obj;
      });

      return transformedData;
    }
  };

 
  

  // const onDateFilterChanged = (selectedDate) => {
  //   const isValidDate = moment(selectedDate, "DD-MM-YYYY", true).isValid();
  //   console.log("Selected Date:", selectedDate);
  //   console.log("Is Valid Date:", isValidDate);
  
  //   if (isValidDate) {
  //     const formattedDate = moment(selectedDate, "DD-MM-YYYY").format("DD-MM-YYYY");
  //     console.log("Formatted Date:", formattedDate);
  
  //     if (gridRef.current && gridRef.current.api) {
  //       const api = gridRef.current.api;
  //       const filterModel = api.getFilterModel();
  //       console.log("Current Filter Model:", filterModel);
  
  //       // Get the current column definitions
  //       const columnDefs = api.getColumnDefs();
  //       console.log("Column Definitions:", columnDefs); 
  
  //       // Loop through column definitions and apply the date filter where dateFilter is true
  //       columnDefs.forEach((colDef) => {
  //         if (colDef.dateFilter) {
  //           console.log("Applying Date Filter to Column:", colDef.field);
  //           filterModel[colDef.field] = {
  //             type: 'equals',
  //             dateFrom: formattedDate,
  //           };
  //         }
  //       });
  
  //       console.log("Updated Filter Model:", filterModel);
  //       api.setFilterModel(filterModel);
  //       api.onFilterChanged();
  //     } else {
  //       console.error('Grid API is not available');
  //     }
  //   } else {
  //     console.error('Invalid date format');
  //   }
  // };

  const onDateFilterChanged = (selectedDate) => {
    console.log(selectedDate, "selected date")
    const momentDate = moment(selectedDate, "DD-MM-YYYY",true);

    if (momentDate.isValid()) {
        const formattedDate = momentDate.format("DD-MM-YYYY"); 
        console.log("Formatted Date for Filtering:", formattedDate);

        if (gridRef.current && gridRef.current.api) {
            const api = gridRef.current.api;
            const filterModel = api.getFilterModel();
            console.log("************")
            api.getColumnDefs().forEach((colDef) => {
              console.log(colDef,"column def")
                if (colDef.dateFilterTable) {
                  console.log(colDef.dateFilterTable,"colDef.dateFilterTable")
                    filterModel[colDef.field] = {
                        type: 'equals',
                        dateFrom: formattedDate,
                        filterType:"date"
                    };
                    console.log(filterModel,"filter model")
                }
            });

            api.setFilterModel(filterModel);
            api.onFilterChanged();
        }
    } else {
        console.log("Invalid date format entered:", selectedDate);
    }
};



  
  

  
  

  const handleColumnVisibilityChange = () => {
    const columnState = gridApi.getColumnState();
    const groupedColumn = columnState.find((column) => column.hide);
    props.onColGroup && props.onColGroup(groupedColumn);
    // setGroupedColumn(groupedColumn ? groupedColumn.colId : null);
  };

  const getAllColumns = () => {
    console.log("all columsn", gridRef.current);
    // const allColumns = gridRef.current.api.getAllColumns();
    // console.log(allColumns);
  };

  const statusBar = {
    statusPanels: [
      {
        statusPanel: "agTotalAndFilteredRowCountComponent",
        align: "left",
      },
      {
        statusPanel: "agTotalRowCountComponent",
        align: "center",
      },
      {
        statusPanel: "agFilteredRowCountComponent",
        align: "center",
      },
      {
        statusPanel: "agSelectedRowCountComponent",
        align: "right",
      },
      {
        statusPanel: "agAggregationComponent",
        align: "right",
      },
    ],
  };

  console.log(props,"props in side bar")



  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
        {
          id: "pdfRenderer",
          labelDefault: "PDF View",
          labelKey: "pdfRenderer",
          iconKey: "pdf-file",
          width: 500,
          toolPanel: props.pdfV2 ? PDFViewerToolV2 : PDFViewerTool,
          toolPanelParams: {
            dataKey: props.pdfDataKey,
            pdfData: props.pdfData,
            
          },
          
        },
      ],
    }),
    [props.pdfData]
  );

  console.log("Grid is rendering");

  return (
    <div className="AgTable">
      {props.showExport || props.leftTopActions || props.rightTopActions ? (
        <div className="TableActionContainer">
          <div className="LeftContainer">{props.leftTopActions}</div>
          <div className="RightContainer">
            {props.rightTopActions}
            <div className="ActionButton">
              {props.showExport ? (
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  shape="round"
                  size="small"
                  style={{
                    fontSize: 12,
                  }}
                  id="agTableExportButton"
                  onClick={exportDataToExcel}
                >
                  {props.exportLabel || "Export"}
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      <div className="ag-theme-alpine">
        <AgGridReact
          rowData={props.rowData}
          popupParent={document.body}
          columnDefs={processColumnDefs(props.columnDefs) || []}
          // defaultColDef={processColumnDefs(props.columnDefs) || []}
          pagination={!props.footer ? false : true}
          paginationPageSize={1000}
          rowSelection="multiple"
          suppressCellSelection={true}
          resizable={true}
          enablePivot={true}
          ref={gridRef}
          enableSorting={true}
          enableFilter={true}
          statusBar={props.showStatusBar && statusBar}
          groupMultiAutoColumn={true}
          rowGroupPanelShow={props.groupPanel ? "always" : "never"}
          pivotPanelShow="always"
          onGridReady={onGridReady}
          grandTotalRow={props.grandTotalRow}
          // pinnedBottomRowData={props.pinnedBottomRowData}
          pivotColumnGroupTotals="before"
          // pivotColumns={["Vendor Name"]}
          // autoGroupColumnDef={["Vendor Name"]}
          // onColumnGrouped={onColumnGrouped}
          //function related to cell/row edit change
          onCellValueChanged={onCellValueChanged}
          onRowValueChanged={onRowValueChanged}
          onColumnRowGroupChanged={onColumnRowGroupChanged}
          //function related to cell/row edit change
          editType={"fullRow"}
          onColumnVisible={handleColumnVisibilityChange}
          suppressClickEdit={true}
          autoSizeStrategy={
            props.autoResize
              ? {
                  type: "fitGridWidth",
                  defaultMinWidth: 100,
                }
              : {}
          }
          defaultColDef={{
            resizable: props.autoResize,
          }}
          sideBar={props.hideToolbar ? {} : sideBar}
          autoGroupColumnDef={{
            headerName: "Group",
            cellRenderer: "agGroupCellRenderer",
            cellRendererParams: {
              innerRenderer: (params) => {
                // Find the original column definition based on the field name
                const originalColumn = props.columnDefs.find(
                  (colDef) => colDef.field === params.node.field
                );
                console.log("mainGroupRow:", params);
                return (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                  >
                    {handleAgCellDataFormat(
                      originalColumn?.formatType || "",
                      params.value,
                      originalColumn?.formatProps || "",
                      params.data || {}
                    ) || <strong>-</strong>}
                  </div>
                );
              },
            },
          }}
        />
      </div>
    </div>
  );
});
export default AgTableClient;
